/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Razor bumps form when cut hair grows back into the skin and forms an irritated bump.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "What razor bumps look like up close...")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Keloid scars are smooth, raised bumps caused by scar tissue (from ingrown hair infections) that continues to grow. Keloids can range in color from flesh-toned to pink or red. They may become larger than the original area.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "What keloids look like up close...")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure05",
    image: "figure05",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure05",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Cause"), React.createElement(_components.p, null, "Normally hair resides and grows within the hair follicle. After shaving, sometimes the hair will grow inward or sideways through the follicle (ingrown hair.)")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "In curly hair, the curved follicle makes it easier for the sharpened hair to grow into the skin.\nThe area becomes inflamed and itchy. When scratched, bacteria can enter the skin. This worsens the inflammation, and causes the melanocytes (pigment factory) to produce excess pigmentation (dark spots or hyperpigmentation), making the area unsightly.")), React.createElement(Pin, {
    id: "figure05",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure06",
    image: "figure06",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure06",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Avoid shaving close to the skin when possible and use a sharp razor. Keeping hair longer than 2mm (0.25 inches) can help prevent razor bumps.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Keep your skin clean and well moisturized.")), React.createElement(Pin, {
    id: "figure06",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "hn6q_fRiBbg",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more on how address razor bumps and keloids.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "How to Prevent Razor Bumps and Keloids"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Always use a ", React.createElement(Obl, {
    to: "https://amzn.to/3UVyF25",
    title: "Amazon Affiliate Link"
  }, "clean razor"), " with ", React.createElement(Obl, {
    to: "https://amzn.to/3AzHIOk",
    title: "Amazon Affiliate Link"
  }, "sharp blades"), " to shave"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Use ", React.createElement(Obl, {
    to: "https://amzn.to/3AzHMxy",
    title: "Amazon Affiliate Link"
  }, "shaving cream"), " to improve the gliding ability of the razor"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Use an antibacterial aftershave (", React.createElement(Obl, {
    to: "https://amzn.to/3VkqCfl",
    title: "Amazon Affiliate Link"
  }, "Tend skin"), ", ", React.createElement(Obl, {
    to: "https://amzn.to/3TWDCXk",
    title: "Amazon Affiliate Link"
  }, "Toner"), ") to kill any bacteria that may infect the skin."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Use an exfoliating agent (", React.createElement(Obl, {
    to: "https://amzn.to/3OpKX0m",
    title: "Amazon Affiliate Link"
  }, "Aftershave lotion"), ") daily to remove the dead skin in order to aid the hair out of the hair follicle. This action also removes the piercing hair tips from causing inflammation as well as removing the dark spots."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "If the area is irritated, painful and associated with pus-filled hair follicles with or without fever, see a doctor immediately."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Keloids can be socially embarrassing, as well as physically and emotionally scarring. They can be treated successfully if caught early. Consult a dermatologist before they become too large."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "For more info - ", React.createElement(Obl, {
    to: "https://www.jamaicaobserver.com/lifestyle/razor-bumps-and-keloids-from-shaving_12391434",
    title: "further material on how to address razor bumps and keloids"
  }, "https://www.jamaicaobserver.com/lifestyle/razor-bumps-and-keloids-from-shaving_12391434")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
